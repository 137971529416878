import { Card, CardContent, Typography, Divider } from "@mui/material";
import React from "react";

const ScriptureCard = ({ title, description }) => {
  return (
    <Card
      sx={{
        height: 350,
        maxHeight: 400,
        width: { xs: 280, md: 380, lg: 280 },
        backgroundColor: "rgba(39,68,114,0.9)",
        color: "white",
        padding: "0px 5px",
        margin: "auto",
      }}
      elevation={12}
    >
      <CardContent>
        <Typography fontSize="1.2rem" fontWeight="bold">
          {title}
        </Typography>
        <Divider
          light
          sx={{
            marginTop: 2,
            marginBottom: 2,
            backgroundColor: "white",
          }}
        />

        <Typography sx={{ fontSize: 16 }}>{description}</Typography>
      </CardContent>
    </Card>
  );
};

export default ScriptureCard;
