import {
  AppBar,
  Box,
  Button,
  Container,
  CssBaseline,
  Divider,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Carousel from "nuka-carousel";
import ReactPlayer from "react-player";
import FacebookIcon from "@mui/icons-material/Facebook";
import ScriptureCard from "./ScriptureCard.jsx";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import IconButton from "@mui/material/IconButton";
import Banner from "./Banner.jsx";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import TranslateIcon from "@mui/icons-material/Translate";
import { useTranslation } from "react-i18next";

export const darkBlue = "#274472";
export const babyBlue = "#5885AF";
export const otherBlue = "#355589";
const navItems = ["No que acreditámos", "Horário", "Contactos"];

const HomePage = () => {
  const { t, i18n } = useTranslation();
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  const matchesXS = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("lg"));
  const screenSize = matchesXS ? "xs" : matchesSM ? "sm" : "md";
  const firstPageRef = useRef(null);
  const footerRef = useRef(null);
  const [navBackground, setNavBackground] = useState("solid");
  let navRef = useRef();

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 100;
      if (show) {
        setNavBackground("transparent");
      } else {
        setNavBackground("solid");
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        {renderNavBar(navRef, firstPageRef, footerRef, navBackground, t, i18n)}
        <Container maxWidth={false} disableGutters>
          <main>
            {renderHeader(t)} {renderContent(firstPageRef, screenSize, t)}
            {renderFooter(footerRef, t)}
          </main>
        </Container>
      </ThemeProvider>
    </>
  );
};

const handleClickNavMenu = (event, firstPageRef, footerRef, t) => {
  if (event.target.innerText === t("believe.title")) {
    firstPageRef.current.scrollIntoView({ behavior: "smooth" });
  } else {
    footerRef.current.scrollIntoView({ behavior: "smooth" });
  }
};

const handleHomeClick = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

const renderNavBar = (
  navRef,
  firstPageRef,
  footerRef,
  navBackground,
  t,
  i18n
) => {
  const backgroundColor =
    navBackground === "solid" ? darkBlue : "rgba(39,68,114,0.9)";
  return (
    <AppBar
      position="fixed"
      ref={navRef}
      sx={{
        backgroundColor: backgroundColor,
        height: "70px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Toolbar>
        <img
          alt="logo"
          style={{
            display: "flex",
            marginRight: 20,
            cursor: "pointer",
            height: "auto",
            width: "45px",
          }}
          src={process.env.PUBLIC_URL + "/favicon.ico"}
        ></img>
        <Typography
          variant="h5"
          color={"white"}
          sx={{
            fontWeight: 700,
            letterSpacing: ".1rem",
            flexGrow: 1,
            cursor: "pointer",
          }}
          onClick={(e) => handleHomeClick()}
        >
          {t("nav.name")}
        </Typography>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
          }}
        >
          {navItems.map((page, index) => (
            <Button
              key={page}
              onClick={(e) => handleClickNavMenu(e, firstPageRef, footerRef, t)}
              sx={{
                my: 2,
                color: "white",
                marginLeft: 1,
                textTransform: "unset",
                fontWeight: "bold",
                height: 35,
                display: "inline-block",
                marginBottom: 2.5,
                ":after": {
                  display: "block",
                  content: `""`,
                  margin: "auto",
                  height: "2px",
                  width: "0px",
                  background: "transparent",
                  transition: "width .5s ease, background-color .5s ease",
                },
                ":hover:after": {
                  width: "100%",
                  background: "white",
                },
              }}
            >
              {t("nav.menu." + index)}
            </Button>
          ))}
          <Button
            variant="text"
            sx={{
              my: 2,
              color: "white",
              fontWeight: "bold",
              display: "inline-block",
              marginLeft: 1,
              textTransform: "unset",
              height: 35,
              ":after": {
                display: "block",
                content: `""`,
                margin: "auto",
                height: "2px",
                width: "0px",
                background: "transparent",
                transition: "width .5s ease, background-color .5s ease",
              },
              ":hover:after": {
                width: "100%",
                background: "white",
              },
            }}
            onClick={() =>
              i18n.changeLanguage(i18n.language === "en" ? "pt" : "en")
            }
          >
            <Box
              sx={{ display: "inline-flex", justifyContent: "space-evenly" }}
            >
              <TranslateIcon fontSize="small" sx={{ marginRight: 0.5 }} />{" "}
              {t("nav.button")}
            </Box>
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const renderCarouselIconLeft = (previousSlide, previousDisabled) => {
  return (
    <IconButton
      onClick={previousSlide}
      disabled={previousDisabled}
      disableRipple
      sx={{
        ":hover": { backgroundColor: "transparent" },
        marginLeft: 2,
      }}
    >
      <ArrowBackIosIcon
        sx={{
          color: "white",
          fontSize: { xs: 25, sm: 50 },
        }}
      />
    </IconButton>
  );
};

const renderCarouselIconRight = (nextSlide, nextDisabled) => {
  return (
    <IconButton
      onClick={nextSlide}
      disabled={nextDisabled}
      disableRipple
      sx={{
        ":hover": { backgroundColor: "transparent" },
        marginRight: 2,
      }}
    >
      <ArrowForwardIosIcon
        sx={{
          color: "white",
          fontSize: { xs: 25, sm: 50 },
        }}
      />
    </IconButton>
  );
};

const renderHeader = (t) => {
  return (
    <Carousel
      autoplay
      wrapAround={true}
      dragging
      swiping
      enableKeyboardControls
      pauseOnHover
      style={{ height: "auto", maxHeight: "450px", marginTop: "70px" }}
      autoplayInterval={5000}
      defaultControlsConfig={{
        pagingDotsStyle: { fill: "white" },
      }}
      renderCenterRightControls={({ nextDisabled, nextSlide }) =>
        renderCarouselIconRight(nextSlide, nextDisabled)
      }
      renderCenterLeftControls={({ previousDisabled, previousSlide }) =>
        renderCarouselIconLeft(previousSlide, previousDisabled)
      }
    >
      <Banner
        imgUrl={process.env.PUBLIC_URL + "/message-hub.png"}
        title={t("banner.0.title")}
        description={t("banner.0.description")}
        backgroundColor={babyBlue}
        fontColor="white"
        onClick={() =>
          openExternalLink("http://www.messagehub.info/pt/messages.do")
        }
      />
      <Banner
        imgUrl={process.env.PUBLIC_URL + "/message-search.png"}
        title={t("banner.1.title")}
        description={t("banner.1.description")}
        backgroundColor="#e4dec8"
        fontColor={darkBlue}
        onClick={() => openExternalLink("https://www.messagesearch.org/")}
      />
      <Banner
        imgUrl={process.env.PUBLIC_URL + "/facebook.png"}
        title={t("banner.2.title")}
        description={t("banner.2.description")}
        backgroundColor={babyBlue}
        fontColor="white"
        onClick={() =>
          openExternalLink("https://www.facebook.com/vozsetimoanjo")
        }
      />
      <a
        style={{ textDecoration: "none" }}
        target={"_blank"}
        rel="noreferrer"
        href={process.env.PUBLIC_URL + t("bookName")}
      >
        <Banner
          imgUrl={process.env.PUBLIC_URL + "/profeta.jpg"}
          title={t("banner.3.title")}
          description={t("banner.3.description")}
          backgroundColor={otherBlue}
          fontColor="white"
          biggerImage={true}
          onClick={undefined}
        />
      </a>
    </Carousel>
  );
};

const openExternalLink = (link) => {
  window.open(link);
};

const renderContent = (firstPageRef, screenSize, t) => {
  return (
    <>
      {renderWhoAreWe(firstPageRef, t)}
      {renderScriptureCards(screenSize, t)}
    </>
  );
};

const renderWhoAreWe = (firstPageRef, t) => {
  return (
    <Container
      maxWidth={"xl"}
      sx={{
        marginBottom: 10,
        paddingTop: 10,
      }}
      ref={firstPageRef}
    >
      <Typography
        variant="h3"
        fontWeight="bold"
        letterSpacing=".1rem"
        color={darkBlue}
        align="center"
        sx={{ mb: 8 }}
      >
        {t("believe.title")}
      </Typography>
      <Box
        sx={{
          display: { md: "flex" },
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: { xs: "80%", md: "50%" },
            marginRight: { md: 15 },
            margin: "auto",
            textAlign: "justify",
            marginBottom: { xs: 8, md: 0 },
          }}
        >
          <Typography variant="h6" color={darkBlue}>
            {t("believe.paragraph1")}
          </Typography>
          <Typography variant="h6" color={darkBlue}>
            <p>{t("believe.paragraph2")}</p>
          </Typography>
        </Box>
        <Box sx={{ width: { xs: "100%", md: "50%" } }}>
          <ReactPlayer width="100%" light url={t("videoUrl")} />
        </Box>
      </Box>
    </Container>
  );
};

const renderFooter = (footerRef, t) => {
  return (
    <footer>
      <Container
        maxWidth={false}
        sx={{
          backgroundColor: darkBlue,
          height: { sm: 120 },
          color: "white",
          display: { sm: "flex" },
          width: "100%",
          alignItems: "center",
          padding: { xs: 2, sm: 0 },
        }}
        ref={footerRef}
      >
        <Box
          sx={{
            display: { sm: "flex" },
            justifyContent: "space-evenly",
            alignItems: "center",
            width: "100%",
            textAlign: { xs: "center" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginRight: { sm: 5 },
              marginBottom: { xs: 2, sm: 0 },
              opacity: "80%",
            }}
          >
            <Typography fontWeight="bold">
              {t("footer.schedule.title")}
            </Typography>
            <Typography variant="caption">{t("footer.schedule.1")}</Typography>
            <Typography variant="caption">{t("footer.schedule.2")}</Typography>
          </Box>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{
              display: { xs: "none" },
              borderColor: "white",
              opacity: "60%",
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginRight: { sm: 5 },
              opacity: "80%",
              marginBottom: { xs: 2, sm: 0 },
            }}
          >
            <Typography fontWeight="bold">
              {t("footer.contacts.title")}
            </Typography>
            <Typography variant="caption">{t("footer.contacts.1")}</Typography>
            <Typography variant="caption">{t("footer.contacts.2")}</Typography>
          </Box>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{
              display: { xs: "none" },
              borderColor: "white",
              opacity: "60%",
            }}
          />
          <Box>
            <Button
              variant="contained"
              startIcon={<FacebookIcon />}
              onClick={() =>
                openExternalLink("https://www.facebook.com/vozsetimoanjo")
              }
              sx={{ margin: "auto", display: "flex", mb: 1 }}
            >
              Facebook
            </Button>
            <Typography
              variant="caption"
              sx={{
                width: "100%",
                opacity: "80%",
                justifyContent: "center",
                display: "flex",
              }}
            >
              Copyright {new Date().getFullYear()} Igreja Voz do Sétimo Anjo
            </Typography>
          </Box>
        </Box>
      </Container>
    </footer>
  );
};

const renderScriptureCards = (screenSize, t) => {
  const cardsToShow = screenSize === "xs" ? 1 : screenSize === "sm" ? 2 : 4;
  return (
    <Container>
      <Carousel
        animation="zoom"
        cellAlign="center"
        autoplay
        swiping
        dragging
        enableKeyboardControls
        pauseOnHover
        style={{
          height: "auto",
          maxHeight: "700px",
          marginBottom: 70,
          paddingBottom: 40,
          paddingTop: 30,
        }}
        autoplayInterval={10000}
        wrapAround={true}
        slidesToShow={cardsToShow}
        renderCenterRightControls={() => <></>}
        renderCenterLeftControls={() => <></>}
      >
        <ScriptureCard
          title={t("cards.0.title")}
          description={t("cards.0.description")}
        ></ScriptureCard>
        <ScriptureCard
          title={t("cards.1.title")}
          description={t("cards.1.description")}
        ></ScriptureCard>
        <ScriptureCard
          title={t("cards.2.title")}
          description={t("cards.2.description")}
        ></ScriptureCard>
        <ScriptureCard
          title={t("cards.3.title")}
          description={t("cards.3.description")}
        ></ScriptureCard>
        <ScriptureCard
          title={t("cards.4.title")}
          description={t("cards.4.description")}
        ></ScriptureCard>
        <ScriptureCard
          title={t("cards.5.title")}
          description={t("cards.5.description")}
        ></ScriptureCard>
        <ScriptureCard
          title={t("cards.6.title")}
          description={t("cards.6.description")}
        ></ScriptureCard>
      </Carousel>
    </Container>
  );
};

export default HomePage;
