import { Box, Container, Typography } from "@mui/material";
import React from "react";

const Banner = ({
  title,
  imgUrl,
  description,
  backgroundColor,
  fontColor,
  biggerImage = false,
  onClick,
}) => {
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        backgroundColor: backgroundColor,
        height: "100%",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        paddingTop: { xs: 4, sm: 0 },
        paddingBottom: { xs: 4, sm: 0 },
      }}
      onClick={onClick}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-around",
          backgroundColor: backgroundColor,
          alignItems: "center",
          color: fontColor,
          padding: 2,
          height: "100%",
        }}
      >
        <Box sx={{ display: { sm: "none" }, mb: 2 }}>
          <Typography variant="h4" align="center">
            {title}
          </Typography>
          <Typography align="center">{description}</Typography>
        </Box>
        <Box
          sx={{
            ml: { sm: 5, md: 2 },
            display: "flex",
            justifyContent: "center",
            width: biggerImage
              ? { xs: "200px", md: "300px" }
              : { xs: "250px", md: "350px" },
          }}
        >
          <img
            alt={title}
            src={imgUrl}
            style={{
              objectFit: "cover",
              height: "auto",
              width: "100%",
              borderRadius: "8px",
              padding: 2,
            }}
          ></img>
        </Box>
        <Box sx={{ ml: 2, display: { xs: "none", sm: "block" } }}>
          <Typography sx={{ mb: 2 }} variant="h3" align="center">
            {title}
          </Typography>
          <Typography align="center">{description}</Typography>
        </Box>
      </Container>
    </Container>
  );
};

export default Banner;
